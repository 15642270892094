import React from "react";
import "./style.css";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIcon from "@mui/icons-material/Phone";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ArticleIcon from "@mui/icons-material/Article";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { Avatar, Chip, Divider, Stack, Typography } from "@mui/material";

export function CV(props): React.ReactElement {
  var softSkills = [
    "Agile",
    "Communication",
    // "Attention to detail",
    "Adaptability",
    "Creativity",
    "Teamwork",
    // "Empathy",
    "Leadership",
  ];
  var techSkills = [
    "Scrum",
    "Git",
    "Azure DevOps",
    "Unity",
    "Heroku",
    "MongoDB",
    "Firebase",
    "Visual Studio",
    "Android Studio",
  ];
  var langSkills = [
    "Xamarin",
    ".NET",
    "C#",
    "T-SQL",
    "SQLite",
    "Java",
    "JavaScript",
    "PowerShell",
    "Gatsby",
    "React.js",
    "Node.js",
    "Express.js",
    "MS SQL Server",
    "Insomnia",
    "Power Platform",
  ];

  techSkills.push(...langSkills);

  return (
    <>
      <Stack className="page">
        <Stack className="top-content" direction="row">
          <Stack className="column-1" direction="column">
            <Stack className="column-section" direction="column">
              <h1>Zach Hucker</h1>
              <h2>Junior Developer</h2>
              <p>
                A recent graduate with 8+ years of experience as a software
                hobbyist. Eager to learn and is dedicated to the exploration of
                best practices in all aspects of software development.
              </p>
            </Stack>
          </Stack>
          <Stack className="column-2" direction="row-reverse">
            <Avatar
              src="https://media-exp1.licdn.com/dms/image/C5603AQFDgZ9ksou1qA/profile-displayphoto-shrink_800_800/0/1658548920399?e=1663804800&v=beta&t=TVrrY8wNWxS2cg2cGGHxvS9XmSA4h5261ao4AP7Nyjk"
              sx={{
                height: "35mm",
                width: "35mm",
                alignSelf: "center",
                justifySelf: "center",
              }}
            />
          </Stack>
        </Stack>
        <Stack className="main-content">
          <Stack className="column-1" direction="column">
            <Stack className="column-section" direction="column">
              <h1>Experience</h1>
              <hr />
              <Stack className="section-content" direction="column">
                <Stack direction="column">
                  <h2>Software Developer Intern</h2>
                  <h3>Yorb Ltd | February 2022 - June 2022 </h3>
                  <ul>
                    <li>
                      Developed end-to-end business solutions for clients.
                    </li>
                    <li>
                      Practiced core Agile methodologies in a team environment.
                    </li>
                    <li>
                      Added features to an interface between two systems and
                      reduced the core processes' runtime by 75%.
                    </li>
                  </ul>
                </Stack>
                <Stack direction="column">
                  <h2>IT Support Technician</h2>
                  <h3>Creature Comforts Pet Lodge | May 2016 - Present</h3>
                  <ul>
                    <li>
                      Maintain company website and business-critical legacy
                      systems.
                    </li>
                    <li>
                      Currently developing new website.
                    </li>
                  </ul>
                </Stack>
              </Stack>
            </Stack>
            <Stack className="column-section" direction="column">
              <h1>Education</h1>
              <hr />
              <Stack className="section-content" direction="column">
                <Stack direction="column">
                  <h2>Bachelor of Information & Communications Technology</h2>
                  <h3>
                    Universal College of Learning | February 2020 - July 2022
                  </h3>
                  <ul>
                    <li>
                      Software Engineering and Web & Mobile Development Double
                      Major.
                    </li>
                    <li>
                      Fufilled leadership roles and mentored others in group
                      projects.
                    </li>
                    <li>
                      Developed full stack web, android and multiplatform
                      applications.
                    </li>
                  </ul>
                </Stack>
                <Stack direction="column">
                  <h2>
                    NZ Certificate in Information Technology Essentials Lvl 4
                  </h2>
                  <h3>
                    Universal College of Learning | July 2019 - December 2019
                  </h3>
                </Stack>
              </Stack>
            </Stack>
            <Stack className="column-section" direction="column">
              <h1>Technologies</h1>
              <hr />
              <Stack className="section-content" direction="column">
                <div className="chip-container">
                  {techSkills.map((s) => {
                    return <Chip label={s} variant="outlined" />;
                  })}
                </div>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="column-2" direction="column">
            <Stack className="column-section" direction="column">
              <h1>Contact</h1>
              <hr />
              <Stack className="column-section" direction="column">
                <Stack direction="row" alignItems="center" gap={1}>
                  <LanguageIcon />
                  <a
                    href="https://zach.hucker.nz"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label={"zach.hucker.nz"}
                  >
                    Zach.Hucker.nz
                  </a>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <EmailIcon />
                  <a
                    href="mailto:Zach@Hucker.nz"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label={"Zach@Hucker.nz"}
                  >
                    Zach@Hucker.nz
                  </a>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <GitHubIcon />
                  <a
                    href="https://github.com/UrsusAquaticus"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label={"https://github.com/UrsusAquaticus"}
                  >
                    /UrsusAquaticus
                  </a>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <LinkedInIcon />
                  <a
                    href="https://www.linkedin.com/in/zach-hucker/"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label={"https://www.linkedin.com/in/zach-hucker/"}
                  >
                    /in/zach-hucker
                  </a>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <PhoneIcon />
                  <span>
                    {process.env.ENV_NAME == "LOCAL"
                      ? process.env.MY_PHONE
                      : "Available Upon Request"}
                  </span>
                </Stack>
              </Stack>
            </Stack>
            <Stack className="column-section" direction="column">
              <h1>Skills</h1>
              <hr />
              <Stack className="section-content" direction="column">
                <div className="chip-container">
                  {softSkills.map((s) => {
                    return <Chip label={s} variant="outlined" />;
                  })}
                </div>
              </Stack>
            </Stack>
            <Stack className="column-section" direction="column">
              <h1>Interests</h1>
              <hr />
              <Stack className="section-content" direction="column">
                <Stack className="column-section" direction="column">
                  <Stack direction="row" alignItems="center" gap={1}>
                    <EmojiNatureIcon />
                    <span>Life Sciences</span>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <ElectricalServicesIcon />
                    <span>DIY Electronics</span>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <ArticleIcon />
                    <span>Wikipedia Rabbitholes</span>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack className="column-section" direction="column">
              <Stack className="column-section" direction="column">
                <h1>References</h1>
                <hr />
                {process.env.ENV_NAME == "LOCAL" ? (
                  <Stack className="section-content" direction="column">
                    <Stack direction="column">
                      <h2>{process.env.REFERENCE_NAME_1}</h2>
                      <h3>{process.env.REFERENCE_TITLE_1}</h3>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <PhoneIcon />
                        <span>{process.env.REFERENCE_CONTACT_1}</span>
                      </Stack>
                    </Stack>
                    <Stack direction="column">
                      <h2>{process.env.REFERENCE_NAME_2}</h2>
                      <h3>{process.env.REFERENCE_TITLE_2}</h3>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <EmailIcon />
                        <span>{process.env.REFERENCE_CONTACT_2}</span>
                      </Stack>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack direction="column">
                    <h2>Available Upon Request</h2>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack className="end-content">
          <Stack className="column-1" direction="column">
            <Stack className="column-section" direction="column">
              <a
                href="https://zach.hucker.nz"
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label={"zach.hucker.nz"}
              >
                Zach.Hucker.nz
              </a>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className="page">
        <Stack className="top-content" direction="row">
          <Stack className="column-1" direction="column">
            <Stack className="column-section" direction="column">
              <h1>Zach Hucker</h1>
              <h2>Junior Developer</h2>
            </Stack>
          </Stack>
        </Stack>
        <Stack className="main-content equal-columns">
          <Stack className="column-1" direction="column">
            <Stack className="column-section" direction="column">
              <h1>Yorb Internship</h1>
              <hr />
              <Stack className="section-content" direction="column">
                <Stack direction="column">
                  <h2>Expense Claim App</h2>
                  <h3>Created with guidance from the lead developer.</h3>
                  <ul>
                    <li>
                      Developed a business solution using the Microsoft Power
                      Platform.
                    </li>
                    <li>
                      2 Power Apps and a series of Power Automate flows which
                      aid and automate the expense claim approval process.
                    </li>
                  </ul>
                </Stack>
                <Stack direction="column">
                  <h2>Synchronise ConnectWise Manage and Azure DevOps</h2>
                  <h3>Worked with the Operations Manager as a client.</h3>
                  <ul>
                    <li>
                      Improved a system which routinely imports ConnectWise
                      Manage tickets with select filters into specific Azure
                      DevOps Kanban boards.
                    </li>
                    <li>
                      This relied heavily on REST API calls between products.
                    </li>
                  </ul>
                </Stack>
                <Stack direction="column">
                  <h2>Duda Widgets</h2>
                  <h3>Collaborated with the Lead Designer.</h3>
                  <ul>
                    <li>
                      Created a series of widgets for use within website
                      building platform.
                    </li>
                    <li>
                      Included customisable cards with flexible collection
                      layouts with the option to populate data from an external
                      source.
                    </li>
                  </ul>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="column-1" direction="column">
            <Stack className="column-section" direction="column">
              <h1>Other Projects</h1>
              <hr />
              <Stack className="section-content" direction="column">
                <Stack direction="column">
                  <h2>Nutrition App</h2>
                  <h3>June 2022</h3>
                  <ul>
                    <li>Gravitated to a leadership role.</li>
                    <li>Defined project scope and structure.</li>
                    <li>Delegated tasks.</li>
                    <li>Mentored others in C# concepts.</li>
                  </ul>
                </Stack>
                <Stack direction="column">
                  <h2>‘TuckBox’ Restaurant App</h2>
                  <h3>November 2021</h3>
                  <ul>
                    <li>Designed and developed an Android app in Java.</li>
                    <li>Applied Material Design principles.</li>
                    <li>
                      Implemented the secure transfer with Firebase Firestore.
                    </li>
                  </ul>
                </Stack>
                <Stack direction="column">
                  <h2>‘Cinemacide’ Media Tracker</h2>
                  <h3>September 2021</h3>
                  <ul>
                    <li>
                      Utilised MVC MERN stack. (Mongo DB, Express.js, React.js,
                      Node.js)
                    </li>
                    <li>
                      Implemented secure authentication, authorisation and 3rd
                      party API.
                    </li>
                  </ul>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack className="end-content">
          <Stack className="column-1" direction="column">
            <Stack className="column-section" direction="column">
              <a
                href="https://zach.hucker.nz"
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label={"zach.hucker.nz"}
              >
                Zach.Hucker.nz
              </a>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
