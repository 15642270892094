import React from "react";
import { Layout, Seo } from "gatsby-theme-portfolio-minimal";
import { CV } from "../components/cv-page";

export default function CVPage() {
  return (
    <>
      <Seo title="Zach Hucker - Curriculum Vitae" />
      <CV />
    </>
  );
}
